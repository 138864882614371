@import '../../shared/constants/breakpoints';

@keyframes imgScale {
  0% {
    transform: translate(-50%, -50%) scale(1);

    opacity: 1;
  }
  100% {
    transform: translate(-50%, -50%) scale(1.1);

    opacity: 0;
  }
}

.payment-body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  width: 585px;
  height: 325px;

  .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 80px;
  }

  .img-wrapper {
    @include media-sm {
      width: 210px;
      height: 210px;

      &::before {
        animation: imgScale 1.5s cubic-bezier(0.63, 0.08, 0.36, 0.89) infinite;
      }
      &::after {
        animation: imgScale 1.5s cubic-bezier(0.63, 0.08, 0.36, 0.89) infinite
          0.75s;
      }
    }
    position: relative;
    z-index: 2;

    display: flex;
    align-items: center;
    justify-content: center;

    box-sizing: border-box;
    width: 185px;
    height: 185px;

    background-color: var(--color-white-100);
    border-radius: 10px;
    &::before,
    &::after {
      content: '';

      position: absolute;
      top: 50%;
      left: 50%;

      width: 100%;
      height: 100%;

      opacity: 1;
      opacity: 0;
      border: 1px solid var(--color-white-100);
      border-radius: 10px;
    }

    .img-qr {
      @include media-sm {
        cursor: pointer;
      }
      width: 100%;
      height: 100%;

      border-radius: 10px;
    }
  }
}
