@import '../../shared/constants/breakpoints';

.payment {
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 60px;

  width: 585px;
  height: 727px;

  &-header {
    position: relative;

    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;

    width: 100%;
  }

  &-body {
    position: relative;

    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    align-items: center;
    justify-content: center;

    width: 100%;

    color: var(--color-dark-80);

    .loader {
      display: flex;
      align-items: center;
      justify-content: center;

      margin-left: 230px;
    }

    .service-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      .service-item {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 157px;
        max-height: 40px;

        border-radius: 3px;
      }
    }
  }
}
