.container {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  min-width: 120px;

  background: var(--color-white-10);
  border-radius: var(--border-radius-3);

  &:hover {
    background: var(--color-white-20);
  }

  &_active {
    background: var(--color-white-100);
    &:hover {
      background: var(--color-white-100);
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: 10px;

  font-weight: var(--font-weight-350);
  color: var(--color-white-50);
}

.top-slot {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;

  width: 100%;
  height: 25px;
}
.ellipses {
  display: flex;
  flex-direction: row;
  gap: 3px;

  width: 36px;
  height: 100%;
}
.ellips {
  width: 100%;
  height: 100%;

  background: url('./../../../shared/icons/ellipse-light.svg') center center
    no-repeat;
  &_active {
    background: url('./../../../shared/icons/ellipse-dark.svg') center center
      no-repeat;
  }
}
.number {
  font-size: var(--font-size-13);
  font-weight: var(--font-weight-350);
  color: var(--color-white-50);

  &_active {
    color: var(--color-dark-60);
  }
}

.bottom-slot {
  width: 100%;
  height: 28px;

  &_visa {
    background: url('./../../../shared/icons/visa-light.svg') left center
      no-repeat;
    &_active {
      background: url('./../../../shared/icons/visa-dark.svg') left center
        no-repeat;
    }
  }
  &_mir {
    background: url('./../../../shared/icons/mir-light.svg') left center
      no-repeat;
    &_active {
      background: url('./../../../shared/icons/mir-dark.svg') left center
        no-repeat;
    }
  }
  &_mastercard {
    background: url('./../../../shared/icons/mastercard-light.svg') left center
      no-repeat;
    &_active {
      background: url('./../../../shared/icons/mastercard-dark.svg') left center
        no-repeat;
    }
  }
}
