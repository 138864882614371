.payment {
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 600px;

  background-color: var(--color-base-dark-blue-light);
}

.payment-body {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: 20px 0;
}

.generate-block {
  display: flex;
  flex-direction: column;
  gap: 10px;

  width: 100%;
  margin-top: 20px;

  &-row {
    display: flex;
    flex-direction: row;
    gap: 10px;

    & > button {
      width: 200px;
    }

    & > textarea {
      width: 100%;
      height: 210px;
    }

    & > a {
      height: 100%;

      & > button {
        height: 100%;
      }
    }
  }
}
