.container {
  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--color-white-10);
  border-radius: var(--border-radius-5);
}

.content {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;

  width: 100%;
  padding: 10px 20px;

  font-weight: var(--font-weight-350);
  color: var(--color-white-100);

  &_active {
    color: var(--color-white-10);
  }
}

.info-slot {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.info-slot-left {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.info-slot-left-card-info {
  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;

  width: 80px;
}

.info-slot-left-system {
  min-width: 36px;
}

.info-slot-right-confirm {
  cursor: pointer;

  display: flex;
  flex-direction: row;
  gap: 5px;
  align-items: center;
}

.info-slot-right-delete {
  cursor: pointer;

  width: 15px;
  height: 15px;

  color: var(--color-white-100);

  &:hover {
    color: var(--color-base-amaranth);
  }
}

.custom-tooltip {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 123px;
  height: 45px;

  font-size: var(--font-size-11);
  color: var(--color-dark-50);

  background: var(--color-white-100);
  border-radius: var(--border-radius-5);

  &[data-placement^="right"] > :global(.tippy-arrow::before) {
    transform: scale(1.3);

    border-right-color: var(--color-white-100);
  }
}
