.container {
  cursor: pointer;

  box-sizing: border-box;
  width: 100%;
  padding: 10px;

  color: var(--color-white-50);

  border: 1px solid var(--color-white-10);
  border-radius: var(--border-radius-3);

  &:hover {
    border: 1px solid var(--color-white-100);
  }

  &_active {
    color: var(--color-white-100);

    background-color: var(--color-white-10);
    border: none;
  }

  .content {
    font-size: var(--font-size-12);
    font-weight: var(--font-weight-500);
    line-height: var(--line-height-15);
    letter-spacing: var(--letter-spacing-middle);
  }
}
