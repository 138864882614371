.checkbox-container {
  cursor: pointer;

  display: flex;
  gap: 10px;
  align-items: center;
}
.checkbox {
  cursor: pointer;

  width: 15px;
  height: 15px;

  border: 1px solid var(--color-white-20);
  border-radius: var(--border-radius-2);

  appearance: none;
  &:hover {
    border: 1px solid var(--color-white-100);
  }
  &:focus {
    border: 1px solid var(--color-base-saffron);
  }
  &:disabled {
    background-color: var(--color-white-10);
    border: none;
  }
}
.checkbox-label {
  font-size: var(--font-size-13);
  font-weight: var(--font-weight-350);
  color: var(--color-white-100);
  &_11 {
    font-size: var(--font-size-11);
    line-height: var(--line-height-15);
    &_disabled {
      color: var(--color-white-50);
    }
  }
  &_13 {
    font-size: var(--font-size-13);
    line-height: var(--line-height-20);
    &_disabled {
      color: var(--color-white-50);
    }
  }
}
.checkbox:checked {
  background-color: var(--color-base-yellow-orange);
  &::after {
    content: '';

    display: flex;
    flex-shrink: 0;

    width: 100%;
    height: 100%;

    background: url('../../icons/checkbox-arrow.svg') center center no-repeat;
  }
}
